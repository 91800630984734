import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["emailText", "infoText", "emailForm", "emailField"];

  updateText(event) {
    event.preventDefault();
    this.emailTextTarget.textContent = "¡Gracias!";
    this.infoTextTarget.textContent = "No olvides chequear tus mails.";
    this.emailFormTarget.style.display = "none";
    const email = this.emailFieldTarget.value;
    let path 
    if (window.location.href.includes('incentives')) {
      path = "/incentives/send_email"
    } else {
      path = "/stimulus/send_email"
    };
    this.request(path, email);
  }

  request(path, email) {
    // Realizar una solicitud Fetch POST
    const csrfToken = document.querySelector("[name='csrf-token']").content;
    fetch(`${path}`, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json", // Ajusta los encabezados según sea necesario
      },
      body: JSON.stringify({ email }),
    });
  }
}
